<template>
    <div v-if="scopedRole" class="vx-card p-6 rounded scoped-role-card">
        <div class="w-full flex flex-row-reverse">
            <button class="sk-btn sk-btn-small btn-border sk-btn-danger rounded-lg" @click.stop="$emit('delete')">
                <feather-icon icon="TrashIcon" class="align-middle" svgClasses="text-danger w-4" />
            </button>
        </div>

        <div>
            <vs-select
                :label="$t('admin.users.form.scope_type')"
                v-model="scopedRole.scopeType"
                class="mt-4 w-full border-grey-light"
                @change="handleScopeTypeChange"
            >
                <vs-select-item v-for="scopeType in scopesAndRolesGroupedByScopeType" :key="scopeType.id" :value="scopeType.id" :text="scopeType.name | capitalize"/>
            </vs-select>

            <vs-select
                v-if="scopedRole.scopeType"
                :label="$t('admin.users.form.role')"
                v-model="scopedRole.role"
                class="mt-4 w-full border-grey-light"
                @change="handleRoleChange"
            >
                <vs-select-item
                    v-for="role in scopeTypeGroup.roles"
                    :key="role.id"
                    :value="role.id"
                    :text="role.name"
                    :disabled="usedRoleIds.includes(role.id)"
                />
            </vs-select>

            <Combobox
                v-if="scopedRole.scopeType && scopedRole.scopeType != 3 && scopedRole.role"
                class="mt-4"
                :label="$t('admin.users.form.scopes')"
                :options="scopeTypeGroup.scopes.map(scope => ({key: scope.id, text: scope.name}))"
                :value="scopedRole.scopes"
                :disabledOptionIds="usedScopeIds"
                @input="handleScopesChange"
                :multiple="true"
            />
        </div>
    </div>
</template>

<script>

import Combobox from "@/modules/Shared/Components/Combobox.vue";

export default {
  name: "UserScopedRole",
    components: {Combobox},
  props: {
      scopesAndRolesGroupedByScopeType: {
          type: Array,
          required: true,
          default: () => []
      },
      scopedRole: {
          type: Object,
          required: false,
          default: () => ({
              scopeType: null,
              role: null,
              scopes: [],
          })
      },
      usedScopeIds: {
          type: Array,
          required: false,
          default: () => []
      },
      usedRoleIds: {
          type: Array,
          required: false,
          default: () => []
      }
  },
  computed: {
    scopeTypeGroup() {
        return this.scopesAndRolesGroupedByScopeType.find(scopeType => scopeType.id == this.scopedRole.scopeType)
    }
  },
  methods: {
      handleScopeTypeChange(scopeType) {
          this.scopedRole.scopeType = scopeType
          this.scopedRole.role = null
          this.scopedRole.scopes = []
      },
      handleRoleChange(role) {
          this.scopedRole.role = role
          this.scopedRole.scopes = []
      },
      handleScopesChange(scopes) {
          this.scopedRole.scopes = scopes
      }
  }
};
</script>

<style scoped>
.scoped-role-card {
    max-width: 420px;
}
</style>


export function parseUserScopedRolesFromAPI(userScopedRoles) {
    return userScopedRoles.reduce((acc, userScopedRole) => {
        if (!userScopedRole.scope) {
            acc.push({
                scopeType: "3",
                role: userScopedRole.role.id,
                scopes: []
            })

            return acc
        }

        const scopedRoleOfType = acc.find(scopedRole => scopedRole.scopeType == userScopedRole.scope.type.id && scopedRole.role == userScopedRole.role.id)
        if (scopedRoleOfType) {
            scopedRoleOfType.scopes.push(userScopedRole.scope.id)
        } else {
            acc.push({
                scopeType: userScopedRole.scope.type.id,
                role: userScopedRole.role.id,
                scopes: [userScopedRole.scope.id]
            })
        }

        return acc
    }, [])
}

export function parseUserScopedRolesToAPI(userScopedRoles) {
    const scopedRoles = []

    userScopedRoles
        .filter(userScopedRole => userScopedRole.scopeType && userScopedRole.role)
        .forEach(userScopedRole => {
            if (userScopedRole.scopeType == 3) {
                scopedRoles.push({
                    role_id: parseInt(userScopedRole.role),
                })
            } else {
                userScopedRole.scopes.forEach(scope => {
                    scopedRoles.push({
                        role_id: parseInt(userScopedRole.role),
                        scope_id: parseInt(scope)
                    })
                })
            }
        })

    return { scoped_roles: scopedRoles }
}

<template>
    <div>
        <h4>{{ $t('admin.roles.title') }}</h4>

        <div class="flex flex-row flex-wrap gap-6 mt-4">
            <UserScopedRole
                v-for="(scopedRole, index) in scopedRoles"
                :key="index"
                :scoped-role="scopedRole"
                :used-role-ids="usedRoleIds"
                :used-scope-ids="usedScopeIds"
                :scopes-and-roles-grouped-by-scope-type="scopeTypeGroups"
                @delete="scopedRoles.splice(index, 1)"
            />
        </div>

        <button class="mt-4 sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg" @click="handleAdd">
            <feather-icon icon="PlusIcon" class="align-middle" svgClasses="text-primary w-4" />
        </button>
    </div>
</template>

<script>

import loader from "@/modules/Shared/Mixins/loader";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import UserScopedRole from "@/modules/Admin/Components/UserScopedRole.vue";

export default {
  name: "UserScopedRoles",
  components: {UserScopedRole},
  mixins: [loader],
  props: {
    isAdmin: {
        type: Boolean,
        required: true
    },
    scopedRoles: {
        type: Array,
        required: true
    },
  },
  data() {
      return {
          scopesAndRolesGroupedByScopeType: [],
      }
  },
  async created() {
      const [scopeTypes, scopes, roles] = await Promise.all([
          AuthorizationService.getScopeTypes(),
          unifyPaginatedResponse(AuthorizationService.getScopes, PaginationService.parseParameters({
              per_page: 30,
              filters: []
          })),
          unifyPaginatedResponse(AuthorizationService.getRoles, PaginationService.parseParameters({
              per_page: 30,
              filters: []
          }))
      ])

      this.scopesAndRolesGroupedByScopeType = scopeTypes.map(scopeType => {
          return {
              ...scopeType,
              scopes: scopes.filter(scope => scope.scope_type.id == scopeType.id),
              roles: roles.filter(role => role.scope_type.id == scopeType.id)
          }
      })
  },
  watch: {
    isAdmin: {
        immediate: true,
        handler: function () {
            if (!this.isAdmin) {
                this.scopedRoles = this.scopedRoles.filter(scopedRole => scopedRole.scopeType != 3)
            }
        }
    }
  },
  computed: {
    usedRoleIds() {
        return this.scopedRoles.map(scopedRole => scopedRole.role).flat()
    },
    usedScopeIds() {
        return this.scopedRoles.map(scopedRole => scopedRole.scopes).flat()
    },
    scopeTypeGroups() {
        return this.isAdmin
            ? this.scopesAndRolesGroupedByScopeType
            : this.scopesAndRolesGroupedByScopeType.filter(scopeType => scopeType.name !== 'admin')
    }
  },
  methods: {
      handleAdd() {
          this.scopedRoles.push({
              scopeType: null,
              role: null,
              scopes: [],
          })
      }
  }
};
</script>
